
import { computed, defineComponent, ref, watch } from 'vue';
import AppBody from './components/app-body/index.vue';
import AppFooter from './components/app-footer/index.vue';
import AppAside from './components/app-aside/index.vue';
import AppHeader from './components/app-header/index.vue';
import { useMenuStore } from '@/store/menu';
import { useRoute } from 'vue-router';
import AppSearch from './components/app-search/index.vue';
import { USER_TYPE_ENUM } from '@/constant/system-info';
import usePermissionConfig from './use-permission-config';

export default defineComponent({
  name: 'MainLayout',
  components: {
    AppAside,
    AppBody,
    AppFooter,
    AppHeader,
    AppSearch,
  },
  setup() {
    const $route = useRoute();
    const permissionConfig = usePermissionConfig();
    const menuStore = useMenuStore();
    const openKeys = ref<string[]>([]);
    const activeKey = ref('');
    const handleMenu = (key: string) => {
      const index = openKeys.value.findIndex(v => v === key);
      if (index === -1) {
        openKeys.value.push(key);
      } else {
        openKeys.value.splice(index, 1);
      }
    };
    const subMenus = computed(() => {
      if (openKeys.value.length === 0) return [];
      const row = menuStore.menus.find(v => v.frontEndUrl === openKeys.value[0]);
      if (row?.children?.length) {
        return row.children;
      }
      return [];
    });
    watch([() => $route, () => menuStore.flatMenus], ([newRoute, newMenus]) => {
      const { name, meta } = newRoute;
      const key = meta?.activeMenu as string || name as string;
      activeKey.value = key;
      const row = newMenus.find(v => v.frontEndUrl === key);
      if (row) {
        const set = new Set([...row.pIds, ...openKeys.value]);
        openKeys.value = [...set];
      }
    }, {
      immediate: true,
      deep: true,
    });
    return {
      permissionConfig,
      USER_TYPE_ENUM,
      handleMenu,
      activeKey,
      openKeys,
      subMenus,
    };
  },
});
