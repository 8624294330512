import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b10e4eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      menu_item: true,
      active: _ctx.activeKey === _ctx.data.frontEndUrl,
    }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleItem && _ctx.handleItem(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.data.resourceName), 1)
  ], 2))
}