
import { defineComponent, PropType } from 'vue';
import { IMenuItem } from '@/store/menu/types';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    activeKey: {
      type: String,
      required: true,
    },
    data: {
      type: Object as PropType<IMenuItem>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const handleItem = () => {
      const {
        frontEndUrl,
        disabled = false,
      } = props.data;
      if (disabled) return;
      router.push({
        name: frontEndUrl,
      });
    };
    return {
      handleItem,
    };
  },
});

