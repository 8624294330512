import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71e86c53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app_aside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menus = _resolveComponent("menus")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, null, {
      default: _withCtx(() => [
        _createVNode(_component_menus, {
          config: _ctx.menus,
          openKeys: _ctx.openKeys,
          activeKey: _ctx.activeKey,
          collapse: false,
          onMenu: _ctx.handleMenu
        }, null, 8, ["config", "openKeys", "activeKey", "onMenu"])
      ]),
      _: 1
    })
  ]))
}