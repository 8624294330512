
import { defineComponent, Transition } from 'vue';

export default defineComponent({
  name: 'AppBody',
  components: {
    Transition,
  },
  setup() {},
});
