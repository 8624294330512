
import { defineComponent, PropType } from 'vue';
import { IMenuItem } from '@/store/menu/types';
import Item from './item.vue';
import { RESOURCE_TYPE_ENUM } from '@/api/menu/type';

export default defineComponent({
  name: 'Menus',
  emits: [
    'menu',
  ],
  components: {
    Item,
  },
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
    openKeys: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    activeKey: {
      type: String,
      required: true,
    },
    config: {
      type: Array as PropType<Array<IMenuItem>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menuBlackList:string[] = [
      'SearchImageList',
    ];
    const handleMenu = (key: string) => {
      emit('menu', key);
    };
    return {
      handleMenu,
      RESOURCE_TYPE_ENUM,
      menuBlackList,
    };
  },
});

