
import { defineComponent, PropType } from 'vue';
import { IMenuItem } from '@/store/menu/types';
import Menus from './menus.vue';

export default defineComponent({
  components: {
    Menus,
  },
  emits: ['menu'],
  props: {
    openKeys: {
      type: Array as PropType<string[]>,
      required: true,
    },
    activeKey: {
      type: String,
      required: true,
    },
    menus: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const handleMenu = (key: string) => {
      emit('menu', key);
    };
    return {
      handleMenu,
    };
  },
});

